<template>
  <div class="loan_item">
    <div class="inner inner1 flex ai-c" />
    <div class="inner inner2">
      <div class="content">
        <h1>Sobre nosotros</h1>
        <div class="left_cell">
          <p class="inner1_text">
            DineroRápido es el líder en préstamos rápidos en México. Nuestra aplicación está diseñada para hacer que el proceso de obtener un préstamo sea rápido, fácil y conveniente. Ya no tendrás que perder tiempo en papeleo interminable o largas esperas en el banco.
          </p>
          <p class="inner1_text">
            Con DineroRápido, puedes solicitar un préstamo desde la comodidad de tu hogar o en cualquier lugar donde te encuentres. Solo necesitas una conexión a Internet y tu teléfono móvil.No importa si tienes historial crediticio bajo o no tienes historial crediticio en absoluto, en DineroRápido estamos comprometidos en brindarte una oportunidad de obtener el préstamo que necesitas. A diferencia de los bancos tradicionales, no exigimos garantías ni avales. Nos enfocamos en tu capacidad de pago y te brindamos una respuesta rápida.
          </p>
          <p class="inner1_text">
            En DineroRápido, la seguridad y privacidad de tus datos personales esnuestra máxima prioridad. Utilizamos las últimas tecnologías de encriptación para proteger tu información y garantizar que solo tú tengas acceso a ella.
          </p>
        </div>
      </div>
    </div>
    <div class="inner inner5">
      <div class="title">
        <h3>¡Contáctanos!</h3>
        <p>Nos alegra que estés interesado en nuestra aplicación DineroRápido para obtener préstamos rápidos y fáciles.
          Si tienes alguna pregunta, inquietud o simplemente deseas obtener más información sobre nuestros servicios,
          no dudes en ponerte en contacto con nosotros.
        </p>
      </div>
      <div class="flex items jc-sb">
        <div v-for="(item,index) in inner5items" :key="index" class="content jc-c ai-c">
          <div class="flex fd-c ai-c">
            <img :src="item.img">
            <p>{{ item.txt }}</p>
            <p>{{ item.txt2 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inner5items: [
        { img: require('@/assets/images/loanImg/email.png'), txt: 'Correo electrónico:', txt2: 'info@dinerorapidoapp.com' },
        { img: require('@/assets/images/loanImg/address.png'), txt: 'Dirección:', txt2: 'Jardines de La Misión 2,Tijuana,\n BCN,22647,Mexico' },
        { img: require('@/assets/images/loanImg/time.png'), txt: 'Horas de servicio: ', txt2: '9:00 - 17:00' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    min-width: 1400px;
    margin: 0 auto;
    overflow: hidden;
    padding: 50px calc((100% - 1100px) / 2) ;ßßß
    h3{
      font-size: 18px;
    }
    .content{
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 30px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #6E60F9;
      }
    }
  }
  .inner1{
    background: url('~@/assets/images/loan_bg.png') no-repeat;
    background-size: 100% 100%;
    height: 480px;
    display: flex;
    text-align: left;
    div{
      border-radius: 20px;
      padding: 0 550px 0 0;
      opacity: 0.8;
      h1{
        font-size: 40px;
        line-height: 140px;
        color: #FFFFFF;
      }
      p{
        color: #FFFFFF;
        font-size: 14px;
        text-align: left;
        width: 100%;
        line-height: 30px;
        padding: 0 0 60px ;
      };
    }
  }
  .inner2{
    .content{
      display: flex;
      flex-direction: column;
      h1{
        text-align: center;
        margin-top: 30px;
      }
      .inner1_text{
        font-size: 13px;
        color: #333;
        line-height: 24px;
        margin: 15px 0 0px;
        padding: 0 110px;
      }
      .left_cell,.right_cell{
        flex: 1;
        // display: flex;
        // justify-content: center;
        // padding: 0 0 60px;s
      }
      .right_cell{
        img{
          margin: 25px auto !important;
          width: 380px;
          height: 380px;
          display: block;
        }
      }
    }
  }
  .inner3{
    // background: url('~@/assets/images/loan_bg2.png') no-repeat;
    background-size: 100% 100%;
    height: 400px;
    display: flex;
    .title{
      text-align: center;
      margin-bottom: 50px;
      p{
        color: #666666;
        font-size: 16px;
        margin: 0;
      }
      h3{
        font-size: 30px;
        color: white;
        text-align: center;
        margin-bottom: 30px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #00BB53;
        //   margin: 20px auto 0;
        // }
      }
    }
    .content{
      width: 35%;
      margin: 0 10px;
      padding-bottom: 20px;
      text-align: center;
      color: black;
      background: white;
      border-radius: 20px;
      div{
        height: 100%;
      }
      img{
        width: 100%;
        border-radius: 20px 20px 0 0;
      }
      h1{
        margin-top: 20px;
        font-size: 18px;
        line-height: 50px;
      }
      p{
        color: #666666;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        padding: 0px 24px  0px;
      }
    }
  }
  .inner4{
    background: #F7F7F7;
    .content{
      display: flex;
      flex-direction: column;
      h1{
        text-align: center;
        margin-top: 30px;
      }
      .inner1_text{
        font-size: 13px;
        color: #333;
        line-height: 24px;
        // margin: 25px 0 40px;
        padding: 0 110px;
      }
      .left_cell,.right_cell{
        flex: 1;
        // display: flex;
        // justify-content: center;
      }
      .right_cell{
        img{
          margin: 25px auto !important;
          width: 380px;
          height: 380px;
          display: block;
        }
      }
    }
  }
  .inner5{
    position: relative;
    padding:100px calc((100% - 1200px) / 2) ;
    background: #F3F3F3;
    white-space: pre-line;
    text-align: center;
    .title{
      margin-bottom: 50px;
      padding-left: 10px;
      p{
        color: #666666;
        font-size: 16px;
        margin: 0;
        padding: 0 200px;
        text-align: center;
        line-height: 25px;
      }
      h3{
        font-size: 30px;
        color: #333333;
        margin-bottom: 30px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #00BB53;
        //   margin: 20px auto 0;
        // }
      }
    }
    .content{
      width: 30%;
      margin: 0 10px;
      padding: 30px 0;
      text-align: center;
      color: white;
      border-radius: 10px;
      background: #333333;
      img{
        width: 30px;
        margin-bottom: 10px;
      }
      h1{
        // margin-top: 20px;
        font-size: 12px;
        line-height: 50px;
      }
      p{
        color: #FFFFFF;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        padding: 0px 24px  0px;
      }
    }
  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    .inner{
      min-width: calc(100vw) !important;
    }
    .inner1{
      display: none;
    }
  .inner2{
    h1{
      margin-top: 30px !important;
    }
    h4{
      padding: 0 20px;
    }
    .inner1_text{
      padding: 0 !important;
    }
    .content{
      padding: 0 20px !important;
      display: flex;
      flex-direction: column;
      .left_cell{
        padding: 0;
      }
      .cell_item{
        width: 100% !important;
        height: 100px;
      }
      img{
        margin-top: 0px;
        width: 300px !important;
        height: 300px !important;
        margin: 0 auto ;
      }
    }
  }
  .inner3{
    min-width: calc(100vw - 40px) !important;
    width: calc(100vw ) !important;
    margin: 0 auto !important;
    height: 100px;
    h3{
      font-size: 25px;
      text-align: center;
      margin-bottom: 20px;
    }
    .flex{
      flex-direction: column;
    }
    .content{
      width: calc(100% - 40px);
      margin-bottom: 50px;
      margin: 0 auto 40px;
      img{
        width: calc(100vw - 40px) !important;
      }
      div{
        width: calc(100vw - 40px) !important;
        p{
          font-size: 12px;
        }
      }
    }
    .title{
      width: 100%;
      margin-bottom: 20px;
    }
    .inner5_item{
      display: flex;
      flex-direction: column;
    }
  }
  .inner4{
    h1{
      margin-top: 30px !important;
    }
    h4{
      padding: 0 20px;
    }
    .inner1_text{
      padding: 0 !important;
    }
    .content{
      padding: 0 20px !important;
      display: flex;
      flex-direction: column;
      .left_cell{
        padding: 0;
      }
      .cell_item{
        width: 100% !important;
        height: 100px;
      }
      img{
        margin-top: 0px;
        width: 300px !important;
        height: 300px !important;
        margin: 0 auto ;
      }
    }
  }
    .inner5{
      padding: 50px calc((100% - 1300px) / 2);
      .title{
        p{
          padding:  0 ;
        }
      }
      .items{
        flex-direction: column;
        .content{
          width: 80vw;
          margin: 0 auto 20px;
        }
      }
    }
  }
}
</style>
